import { DotsHorizontalIcon } from '@radix-ui/react-icons';
import { useOutletContext } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { OrderSchema } from '@/schemas/order';

import type { OrderLayoutOutletContext } from '../../orders.layout';
import type { Row } from '@tanstack/react-table';

interface DataTableRowActionsProps<TData> {
  row: Row<TData>;
}

export function DataTableRowActions<TData>({
  row,
}: DataTableRowActionsProps<TData>) {
  const order = OrderSchema.parse(row.original);
  const { openDialogHandler } = useOutletContext<OrderLayoutOutletContext>();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          variant="ghost"
        >
          <DotsHorizontalIcon className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="w-[160px]">
        <DropdownMenuItem
          onClick={() => {
            openDialogHandler({
              orderId: order.id,
              dialog: 'update-order',
            });
          }}
        >
          Edit
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            openDialogHandler({
              orderId: order.id,
              dialog: 'upload-tags',
            });
          }}
        >
          Upload Tags
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            openDialogHandler({
              orderId: order.id,
              dialog: 'create-tag',
            });
          }}
        >
          Create Single Tag
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
