import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { useBatchesNestedQuery } from '@/hooks/use-batches-nested-query';
import { useCreateTagMutation } from '@/hooks/use-create-tag-mutation';
import { useProductsQuery } from '@/hooks/use-products-query';
import type { CreateTagFormData } from '@/modules/orders/components/forms/create-tag-form';
import {
  createTagFormSchema,
  TagForm,
} from '@/modules/orders/components/forms/create-tag-form';

export function CreateTagDialog() {
  // const { productId, batchId } = useParams() as {
  //   productId: string;
  //   batchId: string;
  // };

  const form = useForm<CreateTagFormData>({
    resolver: zodResolver(createTagFormSchema),
    defaultValues: {
      uid: '',
      sampleInfo: {
        destination: '',
        purpose: '',
        testingCriteria: '',
      },
    },
  });

  const createTagMutation = useCreateTagMutation();
  const [open, setOpen] = useState(false);

  const createTagDialogHandler = () => {
    setOpen(true);
  };
  const createTagHandler = useCallback((tag: CreateTagFormData) => {
    console.log(tag);
    // createTagMutation.mutate(tag);
    setOpen(false);
  }, []);

  const closeDialogHandler = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <>
      <Dialog onOpenChange={setOpen} open={open}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Create new tag</DialogTitle>
            <DialogDescription>
              This action will create a new tag
            </DialogDescription>
          </DialogHeader>
          <TagForm onCancel={closeDialogHandler} onSubmit={createTagHandler} />
        </DialogContent>
      </Dialog>
      <Button onClick={createTagDialogHandler}>Create new tag</Button>
    </>
  );
}
