import { z } from 'zod';

import { formatDate } from '@/helpers/format-date';

// We're keeping a simple non-relational schema here.
// IRL, you will have a schema for your data models.

export const ProductSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  brandId: z.string().uuid(),
  sku: z.string(),
  image: z.string().nullable(),
  templateId: z.string().uuid().nullable(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
  imageUrl: z.string().url().nullable(),
});

export const ProductWithBatchCountSchema = ProductSchema.extend({
  batchCount: z.number(),
});

export type Product = z.infer<typeof ProductSchema>;
export type ProductWithBatchCount = z.infer<typeof ProductWithBatchCountSchema>;
