import { useMutation, useQueryClient } from '@tanstack/react-query';

import { apiPaths } from '@/constants/api-paths';
import { api } from '@/lib/api';
import type { CreateTagFormData } from '@/modules/orders/components/forms/create-tag-form';
import type { Product } from '@/schemas/product';
import { TidSchema } from '@/schemas/tid-schema';

import { useAppSelector } from './use-app-selector';
import { toast } from './use-toast';

interface CreateTidParams {
  brandId: string;
  uid: string;
  roll: string;
  sampleInfo?: {
    destination: string;
    purpose: string;
    testingCriteria: string;
  };
  orderId: string;
}

async function createTid({ brandId, ...tid }: CreateTidParams) {
  const { data } = await api.post<Product>(apiPaths.TIDS({ brandId }), {
    ...tid,
  });

  return TidSchema.parse(data);
}

export function useCreateTagMutation() {
  const selectedBrand = useAppSelector((state) => state.brands.selected);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (tid: CreateTagFormData) =>
      createTid({
        brandId: selectedBrand?.id ?? '',
        ...tid,
      }),
    mutationKey: ['create-tid'],
    onSuccess: () => {
      toast({
        title: 'Tag created',
      });
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['orders', selectedBrand?.id],
      });
    },
  });
}
